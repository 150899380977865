.ui-datepicker {
  display: none;
  z-index: 999999 !important;
}

.ui-datepicker .ui-datepicker-header {
  padding: .2em 0;
  position: relative;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  width: 1.8em;
  height: 1.8em;
  cursor: pointer;
  position: absolute;
  top: 2px;
}

.ui-datepicker .ui-datepicker-next-hover span, .ui-datepicker .ui-datepicker-prev-hover span {
  border-color: #0000 #ff8400 #0000 #0000;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-left-width: 0;
  border-right: 8px solid #ff9635;
  margin-top: -5px;
  margin-left: -8px;
  font-size: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.ui-datepicker .ui-datepicker-next span {
  border-width: 5px 0 5px 8px;
  border-color: #0000 #0000 #0000 #ff9635;
  margin-top: -5px;
  margin-left: 0;
  top: 50%;
}

.ui-datepicker .ui-datepicker-title {
  text-align: center;
  margin: 0 2.3em;
  line-height: 1.8em;
}

.ui-datepicker .ui-datepicker-title select {
  margin: 1px 0;
  font-size: 1em;
}

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
}

.ui-datepicker td span, .ui-datepicker td a {
  text-align: right;
  color: #000;
  padding: 4px;
  text-decoration: none;
  display: block;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  left: auto;
  right: 2px;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  left: auto;
  right: 1px;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 1px;
  border-right-width: 0;
}

.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px;
}

.ui-timepicker-div dl {
  text-align: left;
}

.ui-timepicker-div dl dt {
  height: 25px;
  margin-bottom: -22px;
}

.ui-timepicker-div dl .ui_tpicker_time_label {
  margin-bottom: -25px;
}

.ui-timepicker-div dl dd {
  margin: 0 10px 10px 65px;
}

.ui-timepicker-div td {
  font-size: 90%;
}

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.ui-datepicker {
  box-sizing: border-box;
  background: #efefef;
  border: 4px solid #fff;
  padding: 15px;
  box-shadow: 5px 5px 10px #00000059;
}

.ui-datepicker .ui-datepicker-header {
  background: none;
  border: none;
  padding-bottom: 10px;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
  width: 47%;
  margin-right: 6%;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
  width: 47%;
}

.ui-datepicker .ui-datepicker-calendar td {
  border: 1px solid #cfcfcf;
}

.ui-datepicker .ui-datepicker-calendar td a {
  padding: 4px;
  display: block;
}

.ui-datepicker .ui-datepicker-calendar td .ui-state-hover {
  background: #f9eae7;
}

.ui-datepicker .ui-datepicker-calendar {
  border-collapse: collapse;
  background: #fff;
  border: 1px solid #cfcfcf;
}

.ui-datepicker .ui-datepicker-calendar th {
  text-transform: uppercase;
  background: #efefef;
  border: 1px solid #cfcfcf;
  padding: 4px;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
  text-align: center;
  border: 1px solid #cfcfcf;
}

.ui-datepicker {
  width: auto;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today {
  background: #f3d7d2;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-active {
  background: #ffdf66;
}

.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px;
}

.ui-timepicker-div dl {
  text-align: left;
}

.ui-timepicker-div dl dd {
  margin: 0 0 10px 65px;
}

.ui-timepicker-div td {
  font-size: 90%;
}

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.ui-slider {
  text-align: left;
  position: relative;
}

.ui-slider-horizontal .ui-slider-handle {
  margin-left: -5px;
}

.ui-slider .ui-slider-handle {
  z-index: 2;
  cursor: default;
  position: absolute;
}

.ui-slider-horizontal {
  height: 10px;
  background: #dadada;
  border: none;
  border-radius: 10px;
}

.ui-slider-handle {
  height: 10px;
  width: 10px;
  background: #f98b25;
  border-radius: 10px;
  display: block;
  position: absolute;
}

.ui-timepicker-div {
  padding: 10px 0 5px;
}

.ui-datepicker .ui-datepicker-buttonpane {
  border-top: 1px solid #cfcfcf;
  padding-top: 10px;
  overflow: hidden;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-disabled {
  background: #f9f9f9;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-disabled span {
  color: #959595;
}

.ui-datepicker-cover {
  z-index: -1;
  filter: mask();
  width: 200px;
  height: 200px;
  position: absolute;
  top: -4px;
  left: -4px;
}

/*# sourceMappingURL=index.c9ce6762.css.map */
