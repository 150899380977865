/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.ui-datepicker {
    display: none;
    z-index: 999999 !important;
}

.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
    cursor: pointer;
}

.ui-datepicker .ui-datepicker-next-hover span,
.ui-datepicker .ui-datepicker-prev-hover span {
    border-color: transparent #ff8400 transparent transparent;
}

.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}

.ui-datepicker .ui-datepicker-next {
    right: 2px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -5px;
    font-size: 0px;
    width: 0px;
    height: 0px;
    border: solid;
    border-width: 5px 8px 5px 0;
    border-color: transparent #ff9635 transparent transparent;
}

.ui-datepicker .ui-datepicker-next span {
    margin-left: 0px;
    top: 50%;
    margin-top: -5px;
    border-width: 5px 0 5px 8px;
    border-color: transparent transparent transparent #ff9635;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month-year {
    width: 100%;
}

.ui-datepicker table {
    width: 100%;
    font-size: .9em;
}

.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    padding: 4px;
    text-align: right;
    text-decoration: none;
    color: #000000;
}

.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}

.ui-datepicker-rtl {
    direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: right;
}

.ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}

.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}

.ui-timepicker-div dl {
    text-align: left;
}

.ui-timepicker-div dl dt {
    height: 25px;
    margin-bottom: -22px;
}

.ui-timepicker-div dl .ui_tpicker_time_label {
    margin-bottom: -25px;
}

.ui-timepicker-div dl dd {
    margin: 0 10px 10px 65px;
}

.ui-timepicker-div td {
    font-size: 90%;
}

.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

.ui-datepicker {
    padding: 15px;
    border: #ffffff 4px solid;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #efefef;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.35);
}

.ui-datepicker .ui-datepicker-header {
    background: transparent;
    border: none;
    padding-bottom: 10px;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
    width: 47%;
    margin-right: 6%;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
    width: 47%;
}

.ui-datepicker .ui-datepicker-calendar td {
    border: #cfcfcf 1px solid;
}

.ui-datepicker .ui-datepicker-calendar td a {
    padding: 4px;
    display: block;
}

.ui-datepicker .ui-datepicker-calendar td .ui-state-hover {
    background: #f9eae7;
}

.ui-datepicker .ui-datepicker-calendar {
    background: #ffffff;
    border: #cfcfcf 1px solid;
    border-collapse: collapse;
}

.ui-datepicker .ui-datepicker-calendar th {
    background: #efefef;
    border: #cfcfcf 1px solid;
    padding: 4px;
    text-transform: uppercase;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
    text-align: center;
    border: #cfcfcf 1px solid;
}

.ui-datepicker {
    width: auto;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today {
    background: #f3d7d2;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-active {
    background: #ffdf66;
}

.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}

.ui-timepicker-div dl {
    text-align: left;
}

.ui-timepicker-div dl dd {
    margin: 0 0 10px 65px;
}

.ui-timepicker-div td {
    font-size: 90%;
}

.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

.ui-slider {
    position: relative;
    text-align: left;
}

.ui-slider-horizontal .ui-slider-handle {
    margin-left: -5px;
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    cursor: default;
}

.ui-slider-horizontal {
    height: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    background: #dadada;
}

.ui-slider-handle {
    height: 10px;
    width: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #f98b25;
    display: block;
    position: absolute;
}

.ui-timepicker-div {
    padding: 10px 0 5px 0;
}

.ui-datepicker .ui-datepicker-buttonpane {
    overflow: hidden;
    padding-top: 10px;
    border-top: #cfcfcf 1px solid;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-disabled {
    background: #f9f9f9;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-disabled span {
    color: #959595;
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
    position: absolute;
    /*must have*/
    z-index: -1;
    /*must have*/
    filter: mask();
    /*must have*/
    top: -4px;
    /*must have*/
    left: -4px;
    /*must have*/
    width: 200px;
    /*must have*/
    height: 200px;
    /*must have*/
}